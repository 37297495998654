/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { Link } from "react-router-dom";


const Hero = (props) => {
  return (
    <Fragment>
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div className="">
          <div className="relative shadow-xl  sm:overflow-hidden">
            <div className="absolute inset-0">
              <img
                className="h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                alt="People working on laptops"
              />
              <div className="absolute inset-0 lg:bg-gradient-to-r bg-gradient-to-b from-emerald-500/40 to-comfy-900 mix-blend-multiply" />
            </div>
            <div className="relative lg:h-[700px] px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block lg:w-4/5 mx-auto text-white capitalize">intuitive technology for your business.</span>
                <span className="block text-comfy-200 capitalize">Step in and Let’s make your dreams come true.</span>
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-center md:text-xl text-slate-400 sm:max-w-3xl">
                We bring to the table world class software engineers that are capable of building everything your business needs to go digital.              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <Link
                    to="contact-us"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-comfy-700 bg-white hover:bg-comfy-50 sm:px-8"
                  >
                    Get started
                  </Link>
                  <Link
                    to="contact-us"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-comfy-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                  >
                    Live demo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Hero;
