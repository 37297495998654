import { CalendarIcon, ClockIcon } from '@heroicons/react/outline'
import React, { Fragment } from 'react'
import image1 from "../../assets/blog-1.webp"
import data from "./data.json"


const Blog = () => {
    return (
        <Fragment>
            <div className='max-w-4xl space-y-5 mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24  lg:max-w-7xl lg:pt-24 lg:px-8'>
                <div className='flex items-center mb-11 justify-center'>
                    <h1 className='text-6xl text-center font-bold'>Taking control of your daily life is easy when you know how!</h1>
                </div>
                <div className='flex mb-8 lg:items-center lg:flex-row flex-col justify-center'>
                    <h1 className='text-4xl font-bold underline decoration-emerald'>
                        Recent posts
                    </h1>
                </div>

                <div className='grid grid-cols-1 gap-x-11 gap-y-4 md:grid-cols-2'>

                    {data.map((item, index) => (
                        <div className='flex flex-col cursor-pointer'>
                            <img src={image1} alt="" className='rounded-md lg:grayscale hover:grayscale-0 transition-all duration-500' />

                            <div className='flex flex-col mt-4'>
                                <div className='flex justify-between items-center lg:w-2/3 mb-3'>
                                    <h5 className='text-lg flex  space-x-3 font-thin text-gray-700 items-center'>
                                        <CalendarIcon className='h-4 w-4 text-slate-600' />
                                        <span className=''>
                                            March 20, 2020
                                        </span>
                                    </h5>
                                    <hr className='h-1 bg-slate-400 w-4' />
                                    <h5 className='text-lg flex  space-x-3 font-thin text-gray-700 items-center'>
                                        <ClockIcon className='h-4 w-4 text-slate-600' />
                                        <span className=''>
                                            March 20, 2020
                                        </span>
                                    </h5>
                                </div>
                                <h5 className='text-sm text-gray-700'>#{item?.tag}</h5>
                                <h3 className='text-3xl font-bold capitalize hover:text-yellow-400'>
                                    {item?.title}
                                </h3>

                                <p className='text-gray-400'>{item?.body}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    )
}

export default Blog