import React from 'react'
import Image1 from "../../../assets/banner (2).png"
import Image2 from "../../../assets/Firmus-Advisory-Logo-Ghana-2.png"
import Image3 from "../../../assets/irisk-logo-big.png"
import Image5 from "../../../assets/deli.png"
import Image4 from "../../../assets/visal.png"

const LogoCloud = () => {
    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
                    Businesses we have transformed
                </p>
                <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
                    <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                        <img className="h-12" src={Image1} alt="Tuple" />
                    </div>
                    <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                        <img className="h-12" src={Image2} alt="Mirage" />
                    </div>
                    <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                        <img
                            className="h-12"
                            src={Image3}
                            alt="StaticKit"
                        />
                    </div>
                    <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1">
                        <img
                            className="h-12"
                            src={Image4}
                            alt="Transistor"
                        />
                    </div>
                    <div className="col-span-2 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">
                        <img
                            className="h-12"
                            src={Image5}
                            alt="Workcation"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoCloud