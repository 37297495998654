import React, { useState } from 'react'
import { useEffect } from 'react';
import Dash from "../../assets/dash.png"

const CommingSoonBanner = () => {
    const [showBanner, setShowBanner] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShowBanner(true)
        }, 3000);
    }, [])
    return !showBanner ? null : (
        <>
            <div className="fixed   inset-x-0 top-0  bottom-0">
                <div className="bg-comfy-500">
                    <div className="mx-auto max-w-7xl py-4 px-3 sm:px-6 lg:px-8">
                        <div className="flex flex-wrap items-center justify-between">
                            <div className="flex w-0 flex-1 items-center">
                                <span className="flex rounded-lg bg-comfy-300 p-2">
                                    {/* <MegaphoneIcon className="h-6 w-6 text-white" aria-hidden="true" /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 text-white h-8">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
                                    </svg>

                                </span>
                                <p className="ml-3 flex flex-col truncate font-medium text-white">
                                    <span className="md:hidden">Big news! </span>
                                    <span className="md:hidden">We're excited to announce a brand new product.</span>
                                    <span className="hidden md:inline text-lg">Big news! We're excited to announce a brand new product.</span>
                                </p>
                            </div>
                            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">

                            </div>
                            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                                <button
                                    type="button"
                                    onClick={() => setShowBanner(false)}
                                    className="-mr-1 flex rounded-md p-2 hover:bg-comfy-200 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 text-white h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>

                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full flex-1 overflow-y-scroll lg:h-auto h-full bg-gray-200  flex justify-end'>
                    <div className='lg:w-11/12 w-full  bg-gray-200 p-11'>

                        <div class="relative items-center flex flex-col lg:flex-row py-16">
                            <div class="relative px-4 sm:px-6 lg:px-8">
                                <div class="mx-auto max-w-prose text-lg">
                                    <h1>
                                        <span class="block text-center text-lg font-semibold text-comfy-300">Introducing</span>
                                        <span class="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">Comfy Reinsurers Dashboard</span>
                                    </h1>
                                    <p class="mt-8 text-xl leading-8 text-gray-500">Hey! Comfy has created an ecosystem of reinsurance administration through a network that enables an inter connective interaction of reinsurers with other reinsurance stakeholders such as brokers and insurers.
                                        We are launching this next phase of our reinsurance software in the coming months.</p>
                                </div>
                                <div class="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
                                    <p>Through our ecosystem Reinsurers will be able to:</p>
                                    <ul >
                                        <li>Access to reinsurance documents such as fac credit note and treaty cover notes records via our Api .</li>
                                        <li>Management of capacity per insurance policy .</li>
                                        <li>Report on payments remotely generated for offers.</li>
                                        <li>Management of reinsurance offers.</li>
                                        <li>Remote issuance of guarantee Notes .</li>
                                    </ul>
                                </div>
                                <div class="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
                                    <p>Stay tuned!</p>
                                    <p>Comfy team.</p>
                                </div>
                            </div>
                            <div className='relative'>
                                <div className='w-full absolute bg-gray-200 h-11'></div>
                                <img src={Dash} className="object-right-top lg:h-96" alt="" />
                            </div>
                        </div>

                    </div>
                </div>



            </div>
        </>
    )
}

export default CommingSoonBanner