/* eslint-disable jsx-a11y/anchor-is-valid */

import {
    InboxIcon,
    SparklesIcon,
} from '@heroicons/react/outline'
import CTASection from './sections/CTASection'
import GradientFeatureSection from './sections/GradientFeatureSection'
import Hero from './sections/Hero'
import LogoCloud from './sections/LogoCloud'
import StatsSection from './sections/StatsSection'
import WhyComfy from './sections/WhyComfy'
import Frame1 from "../../assets/Frame 1.png"
import Frame2 from "../../assets/Frame 2.png"
import { Link } from 'react-router-dom'


export default function HomePage() {
    return (
        <div className="bg-white">
            <main>
                {/* Hero section */}
                <Hero />

                {/* Logo Cloud */}
                <LogoCloud />

                {/* Alternating Feature Sections */}
                <div className="relative pt-16 pb-32 overflow-hidden">
                    <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
                    <div className="relative">
                        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                                <div>
                                    <div>
                                        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-comfy">
                                            <InboxIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </span>
                                    </div>
                                    <div className="mt-6">
                                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                            Facultative Reinsurance Broking made simpler with Comfy Reinsurance Broking System.
                                        </h2>
                                        <p className="mt-4 text-lg text-gray-500">
                                            At Comfy, convenience and comfort is key. Your days of struggling with manual reinsurance Broking processes are over when we take over the wheels with our Comfy reinsurance Broking system. Manage & control the placement of facultative risk with our interactive dashboard in & out of your office with worldwide accessibility.                                        </p>
                                        <div className="mt-6">
                                            <Link
                                                to="#"
                                                className="inline-flex bg-comfy bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-emerald-700 hover:to-comfy-700"
                                            >
                                                Read more
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-8 border-t border-gray-200 pt-6">
                                    {/* <blockquote>
                                        <div>
                                            <p className="text-base text-gray-500">
                                                &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean
                                                curabitur donec aliquet. Mi venenatis in euismod ut.&rdquo;
                                            </p>
                                        </div>
                                        <footer className="mt-3">
                                            <div className="flex items-center space-x-3">
                                                <div className="flex-shrink-0">
                                                    <img
                                                        className="h-6 w-6 rounded-full"
                                                        src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="text-base font-medium text-gray-700">
                                                    Marcia Hill, Digital Marketing Manager
                                                </div>
                                            </div>
                                        </footer>
                                    </blockquote> */}
                                </div>
                            </div>
                            <div className="mt-12 sm:mt-16 lg:mt-0">
                                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                    <img
                                        className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                        src={Frame1}
                                        alt="Inbox user interface"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-24">
                        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                                <div>
                                    <div>
                                        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-comfy">
                                            <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </span>
                                    </div>
                                    <div className="mt-6">
                                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                            Stay on top of Treaty Broking
                                        </h2>
                                        <p className="mt-4 text-lg text-gray-500">
                                            As a reinsurance broker, proper management of treaty programs is necessary for the protection of your clients risk portfolio.
                                            Our system helps you manage and generate treaty cover notes,
                                            handle excess of loss recoveries and generate quarterly treaty statements from bordereaux submissions.
                                        </p>
                                        <div className="mt-6">
                                            <Link
                                                to="#"
                                                className="inline-flex bg-comfy bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-emerald-700 hover:to-comfy-700"
                                            >
                                                Read more
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                    <img
                                        className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                                        src={Frame2}
                                        alt="Customer profile user interface"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Why Comfy */}
                <WhyComfy />


                {/* Gradient Feature Section */}
                <GradientFeatureSection />

                {/* Stats section */}
                <StatsSection />

                {/* Blogs */}
                {/* <Stories /> */}

                {/* CTA Section */}
                <CTASection />
            </main>

        </div>
    )
}
