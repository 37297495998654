import { AdjustmentsIcon, ClipboardCheckIcon, CogIcon, UserGroupIcon } from '@heroicons/react/outline'
import React, { Fragment } from 'react'

const WhyComfy = () => {
    return (
        <Fragment>
            <div className='max-w-4xl space-y-5 mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8'>
                <h2 className='text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                    <span className='block text-center'>Why Comfy?</span>
                    {/* <span className='block bg-comfy bg-clip-text text-transparent'>
                        We are a team of experienced software developers and designers.
                    </span> */}
                </h2>
                <div className='grid grid-cols-1  md:grid-cols-2'>
                    <div className='bg-slate-50 p-6 transition-all border duration-500 hover:bg-blend-darken hover:bg-emerald-400 cursor-pointer group md:space-x-3 rounded-none hover:shadow-md transform hover:z-10 lg:even:hover:-translate-x-4 lg:even:hover:-translate-y-4 lg:odd:hover:translate-x-4 lg:odd:hover:translate-y-4 flex flex-col md:flex-row'>
                        <div className='md:w-64 w-full p-4 flex lg:items-center lg:justify-center'>
                            <AdjustmentsIcon className='md:h-22 md:w-22 h-11 w-11 group-hover:text-white transition-all duration-500  text-comfy-300' />
                        </div>
                        <div className='md:w-auto w-full group-hover:italic transition-all duration-500'>
                            <h3 className='text-3xl font-bold text-gray-900'>
                                <span className='block'>
                                    24/7 Hours Fast Support
                                </span>
                                <span className='block py-4 text-gray-500 group-hover:text-gray-100 first-letter:text-4xl text-base leading-loose'>
                                    Our dedicated customer service team together with our team of skilled developers
                                    remain at the disposal of our product owners during and after each product development.
                                </span>
                            </h3>
                        </div>
                    </div>
                    <div className='bg-slate-50 p-6 transition-all border duration-500 hover:bg-blend-darken hover:bg-emerald-400 cursor-pointer group md:space-x-3 rounded-none hover:shadow-md transform hover:z-10 lg:even:hover:-translate-x-4 lg:even:hover:-translate-y-4 lg:odd:hover:translate-x-4 lg:odd:hover:translate-y-4 flex flex-col md:flex-row'>
                        <div className='md:w-64 w-full p-4 flex lg:items-center lg:justify-center'>
                            <CogIcon className='md:h-22 md:w-22 h-11 w-11 group-hover:text-white transition-all duration-500  text-comfy-300' />
                        </div>
                        <div className='md:w-auto w-full group-hover:italic transition-all duration-500'>
                            <h3 className='text-3xl font-bold text-gray-900'>
                                <span className='block'>
                                    Start to End Security
                                </span>
                                <span className='block py-4 text-gray-500 group-hover:text-gray-100 first-letter:text-4xl text-base leading-loose'>
                                    At Comfy, security is our topmost priority. All our project data and third party data are fully protected with start to end encryption security.
                                </span>
                            </h3>
                        </div>
                    </div>
                    <div className='bg-slate-50 p-6 transition-all border duration-500 hover:bg-blend-darken hover:bg-emerald-400 cursor-pointer group md:space-x-3 rounded-none hover:shadow-md transform hover:z-10 lg:even:hover:-translate-x-4 lg:even:hover:-translate-y-4 lg:odd:hover:translate-x-4 lg:odd:hover:translate-y-4 flex flex-col md:flex-row'>
                        <div className='md:w-64 w-full p-4 flex lg:items-center lg:justify-center'>
                            <ClipboardCheckIcon className='md:h-22 md:w-22 h-11 w-11 group-hover:text-white transition-all duration-500  text-comfy-300' />
                        </div>
                        <div className='md:w-auto w-full group-hover:italic transition-all duration-500'>
                            <h3 className='text-3xl font-bold text-gray-900'>
                                <span className='block'>
                                    IT Consulting
                                </span>
                                <span className='block py-4 text-gray-500 group-hover:text-gray-100 first-letter:text-4xl text-base leading-loose'>
                                    We offer consulting services in all information technology related areas.
                                    Our team of experts constantly assess the IT space to identify new and improved technology that are adaptable to the ever changing business environment.
                                </span>
                            </h3>
                        </div>
                    </div>
                    <div className='bg-slate-50 p-6 transition-all border duration-500 hover:bg-blend-darken hover:bg-emerald-400 cursor-pointer group md:space-x-3 rounded-none hover:shadow-md transform hover:z-10 lg:even:hover:-translate-x-4 lg:even:hover:-translate-y-4 lg:odd:hover:translate-x-4 lg:odd:hover:translate-y-4 flex flex-col md:flex-row'>
                        <div className='md:w-64 w-full p-4 flex lg:items-center lg:justify-center'>
                            <UserGroupIcon className='md:h-22 md:w-22 h-11 w-11 group-hover:text-white transition-all duration-500  text-comfy-300' />
                        </div>
                        <div className='md:w-auto w-full group-hover:italic transition-all duration-500'>
                            <h3 className='text-3xl font-bold text-gray-900'>
                                <span className='block'>
                                    Virtual Workstation
                                </span>
                                <span className='block py-4 text-gray-500 group-hover:text-gray-100 first-letter:text-4xl text-base leading-loose'>
                                    We provide state of the art, high speed, cloud based workstations that can allow your employees access from anywhere in the
                                    world via the internet the required tools and information needed for execution of a particular task.
                                </span>
                            </h3>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default WhyComfy