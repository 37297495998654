import React from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    MenuIcon,
    XIcon,
} from '@heroicons/react/outline'
import { Link, NavLink } from 'react-router-dom'
import Logo from "../assets/comfy_png_logo.png"



const Navbar = () => {
    return (
        <>
            <header>
                <Popover className="relative bg-white">
                    <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <Link to="/">
                                <span className="sr-only">Workflow</span>
                                <img
                                    className="h-8 w-auto sm:h-10"
                                    src={Logo}
                                    alt=""
                                />
                            </Link>
                        </div>
                        <div className="-mr-2 -my-2 md:hidden">
                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-comfy-500">
                                <span className="sr-only">Open menu</span>
                                <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                        </div>
                        <Popover.Group as="nav" className="hidden md:flex space-x-10">

                            <NavLink to="/" className={({ isActive }) => `text-base  font-medium text-${isActive ? "green" : "gray"}-500 hover:text-gray-900`}>
                                Home
                            </NavLink>

                            <NavLink to="about-us" className={({ isActive }) => `text-base  font-medium text-${isActive ? "green" : "gray"}-500 hover:text-gray-900`}>
                                About Us
                            </NavLink>

                            <NavLink to="contact-us" className={({ isActive }) => `text-base  font-medium text-${isActive ? "green" : "gray"}-500 hover:text-gray-900`}>
                                Contact Us
                            </NavLink>

                            {/* <NavLink to="blog" className={({ isActive }) => `text-base  font-medium text-${isActive ? "green" : "gray"}-500 hover:text-gray-900`}>
                                Blog
                            </NavLink> */}

                        </Popover.Group>

                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        >
                            {({ close }) => (
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                    <div className="pt-5 pb-6 px-5">
                                        <div className="flex items-center justify-between">
                                            <div>
                                                <img
                                                    className="h-8 w-auto"
                                                    src="http://comfytechnology.tech/assets/img/comfy.png"
                                                    alt="Workflow"
                                                />
                                            </div>
                                            <div className="-mr-2">
                                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-comfy-500">
                                                    <span className="sr-only">Close menu</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </Popover.Button>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="py-6 px-5">
                                        <div className="grid grid-cols-2 gap-4">
                                            <NavLink onClick={close} to="/" className={({ isActive }) => `text-base  font-medium text-${isActive ? "green" : "gray"}-500 hover:text-gray-900`}>
                                                Home
                                            </NavLink>

                                            <NavLink onClick={close} to="about-us" className={({ isActive }) => `text-base  font-medium text-${isActive ? "green" : "gray"}-500 hover:text-gray-900`}>
                                                About Us
                                            </NavLink>

                                            <NavLink onClick={close} to="contact-us" className={({ isActive }) => `text-base  font-medium text-${isActive ? "green" : "gray"}-500 hover:text-gray-900`}>
                                                Contact Us
                                            </NavLink>

                                            <NavLink onClick={close} to="blog" className={({ isActive }) => `text-base  font-medium text-${isActive ? "green" : "gray"}-500 hover:text-gray-900`}>
                                                Blog
                                            </NavLink>

                                        </div>
                                    </div>
                                </div>
                            )}
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </header>

        </>
    )
}

export default Navbar