import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../sections/Footer'
import Navbar from '../sections/Navbar'
import CommingSoonBanner from './components/CommingSoonBanner'


const DefaultLayout = () => {
    return (
        <>
            <Navbar />
            <Outlet />
            <Footer />
            <CommingSoonBanner />
        </>
    )
}

export default DefaultLayout