/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const CTASection = () => {
    return (
        <Fragment>
            <div className="bg-white">
                <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
                    <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        <span className="block">Ready to get started?</span>
                        <span className="block bg-comfy bg-clip-text text-transparent">
                            Get in touch or request a demo.
                        </span>
                    </h2>
                    <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
                        <Link
                            to="contact-us"
                            className="flex items-center capitalize justify-center bg-comfy bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-emerald-700 hover:to-comfy-700"
                        >
                            Get Started
                        </Link>
                        <Link
                            to="contact-us"
                            className="flex capitalize items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-comfy-800 bg-comfy-50 hover:bg-comfy-100"
                        >
                            Request a demo
                        </Link>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CTASection