import React, { Fragment } from 'react'
import {
    ChartBarIcon,
    ClipboardCheckIcon,
    DocumentReportIcon,
    DuplicateIcon,
    InboxIcon,
    PencilAltIcon,
    UsersIcon,
} from '@heroicons/react/outline'
import { CashIcon } from '@heroicons/react/solid'

const features = [
    {
        name: 'Unlimited Risk Management',
        description: 'Users can manage the offer cycle(open, pending, closed) as well as include updates such as endorsements.',
        icon: InboxIcon,
    },
    {
        name: 'Manage Employees',
        description: "Managers can also supervise all activities and transactions that are performed by our employee through our push system notifications that are delivered both online and via sms.",
        icon: UsersIcon,
    },
    {
        name: 'Receipt Generation',
        description: 'Users can generate reports in any preferred format with an export to pdf or excel feature.',
        icon: DuplicateIcon,
    },
    {
        name: 'Email Integration',
        description: 'All documents can be sent directly from the system to the desired contacts without having to log into your email application.',
        icon: PencilAltIcon,
    },
    {
        name: 'Finance and Production Reporting',
        description: 'Users can generate profit commission and premium adjustment statements at the end of each underwriting year.',
        icon: ChartBarIcon,
    },
    {
        name: 'Payment Integeration',
        description: 'Payments records can be created and updated via the platform. Reminders on outstanding payments and expired policies can also be sent directly from the system to the designated parties.',
        icon: CashIcon,
    },
    {
        name: 'Treaty Management',
        description: 'Treaty placing slips can be generated from the software upon entry of treaty terms from reinsurers.',
        icon: ClipboardCheckIcon,
    },
    {
        name: 'Dcoument Verification',
        description: 'Users can set permissions such as document approval for managers who need to review generated documents such as placing slip, closing slip, cover and debit notes.',
        icon: DocumentReportIcon,
    },
]

const GradientFeatureSection = () => {
    return (
        <Fragment>
            <div className="bg-gradient-to-r from-emerald-800 to-comfy-700">
                <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
                    <h2 className="text-3xl font-extrabold text-white tracking-tight">Stay ahead of your competition.</h2>
                    <p className="mt-4 max-w-3xl text-lg text-emerald-200">
                        Enjoy World class features to make you work smarter and efficiently from the your comfort zone.
                    </p>
                    <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name}>
                                <div>
                                    <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                                </div>
                                <div className="mt-6">
                                    <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                                    <p className="mt-2 text-base text-emerald-200">{feature.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default GradientFeatureSection